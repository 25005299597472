var userContext = {
    user: {id: '', mail: '', token: '', courses: [], lastCourse: '', lastCourseId: null},
    setUser: setUser,
    updateUser: updateUser,
    isLogged: isLogged,
    loadUser: loadUser,
    logout: logout,
    isSuscribedToCourse: isSuscribedToCourse
}

Object.defineProperty(userContext, 'user', {value: {mail: '', token: ''}, writable: true});

function setUser(userResponse) {
    userContext.user.id = userResponse.id;
    userContext.user.mail = userResponse.mail;
    userContext.user.token = userResponse.token;
    userContext.user.lastCourse = userResponse.lastCourse;
    window.localStorage.setItem('userMail', userResponse.mail);
    window.localStorage.setItem('userToken', userResponse.token);
}

function updateUser() {
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userContext.user)
    };

    fetch(`${process.env.GATSBY_API_URL}api/students/updateUser`, request)
    .then(response => response.json())
    .then(resultData => {
        console.log(resultData)
    }).catch(error => {
        console.log(error);
    })
}

function loadUser() {
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userContext.user)
    };

    return new Promise( (resolve) => {
        fetch(`${process.env.GATSBY_API_URL}api/students/getUser`, request)
        .then(response => response.json())
        .then(resultData => {
            if (resultData.status == "OK") {
                userContext.user.id = resultData.id;
                userContext.user.lastCourse = resultData.lastCourse;
                userContext.user.lastCourseId = resultData.lastCourseId;
                userContext.user.courses = resultData.courses;
                
                for (var key of Object.keys(userContext.user.courses)) {
                    var entry = userContext.user.courses[key];
                    if (entry.sectionsCompleted) {
                        userContext.user.courses[key].sectionsCompleted = JSON.parse(entry.sectionsCompleted);
                    }
                }
                
            }
            resolve(resultData.status);
        }).catch(error => {
            console.log(error);
            resolve("ERROR");
        })
     });
}

function isLogged() {
    userContext.user.mail = window.localStorage.getItem('userMail');
    userContext.user.token = window.localStorage.getItem('userToken');

    return userContext.user.mail && userContext.user.token;
}

function logout() {
    window.localStorage.removeItem('userMail');
    window.localStorage.removeItem('userToken');
}

function isSuscribedToCourse(courseId) {
    if (!courseId) {
        return false;
    }
    if (!userContext.user || !userContext.user.courses[courseId]) {
        return false;
    }
    return userContext.user.courses[courseId].subscription == true;
}

export default userContext;