import { mainStyles, mainContentDiv, mainContentCenterDiv, mainContentLeftTitleDiv,
    mainContentLeftTLogoDiv, logoImg, mainContentLeftRowDiv, mainContentLeftFormDiv, mainContentLeftFormLabelDiv,
    mainContentLeftFormInputDiv, mainContentLeftFormFullDiv, loginButton, loginDiv, mainContentLeftFormLabelLineDiv,
    mainContentLeftFormErrorDiv, successfulAccountMsgDiv, loginButtonDisabled, errorMsgDiv, noAccountDiv, noAccountLink } from './login.module.css'
import React, { useState } from "react"
import Footer from "../components/footer"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby'
import userContext from "../utils/userContext"

const LoginPage = ({ location }) => {
    
    const params = new URLSearchParams(location.search);

    const [showLoading, setShowLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const [formData, setFormData] = useState({
        mail: '',
        password: '',
    });

    const [formError, setFormError] = useState({
        mail: '',
        password: '',
    });

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    function login() {
        var isValid = true;
        var formErrorCopy = JSON.parse(JSON.stringify(formError));
        if (formData.mail.length == 0) {
            isValid = false;
            formErrorCopy['mail'] = "Ingrese su correo electrónico";
        } else {
            formErrorCopy['mail'] = "";
        }
        if (formData.password.length == 0) {
            isValid = false;
            formErrorCopy['password'] = "Ingrese su contraseña";
        } else {
            formErrorCopy['password'] = "";
        }

        setFormError(formErrorCopy);

        if (!isValid) {
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        setShowLoading(true);
        fetch(`${process.env.GATSBY_API_URL}api/students/authenticate`, requestOptions)
        .then(response => response.json())
        .then(resultData => {
            if (resultData.status == "OK") {
                setErrorMsg(null);
                userContext.setUser(resultData);
                if (resultData.lastCourse) {
                    navigate('/curso/' + resultData.lastCourse);
                } else {
                    navigate('/curso/curso-tortas-de-cumpleanos/');
                }
            } else {
                setErrorMsg("Usuario o contraseña invalido.");
            }
            setShowLoading(false);
        }).catch(error => {
            console.log(error);
            setShowLoading(false);
        })
    }

  return (
    <div className={mainStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login - Fede Cocina</title>
          <meta name="description" content="Curso de Pasteleria"/>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
        </Helmet>
        <div className={mainContentDiv}>
            <div className={mainContentCenterDiv}>
                <div className={mainContentLeftTLogoDiv} onClick={() => navigate('/')}>
                    <StaticImage className={logoImg} src="../images/LogoHorizontal_2.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
                </div>
                { params.get("origin") && !errorMsg ? 
                    <div className={successfulAccountMsgDiv}>
                        Se ha creado tu cuenta correctamente! Ingresa aqui abajo para continuar.
                    </div>
                : 
                <></>
                }
                { errorMsg ? 
                    <div className={errorMsgDiv}>
                        {errorMsg}
                    </div>
                : 
                <></>
                }
                <div className={mainContentLeftTitleDiv}>
                    Ingresa a tu cuenta
                </div>
                <div className={mainContentLeftRowDiv}>
                    <div className={mainContentLeftFormDiv}>
                        <div className={mainContentLeftFormFullDiv}>
                            <div className={mainContentLeftFormLabelLineDiv}>
                                <div className={mainContentLeftFormLabelDiv}>
                                    Correo electrónico
                                </div>
                                { formError.mail ?
                                    <div className={mainContentLeftFormErrorDiv}>
                                        {formError.mail}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <input className={mainContentLeftFormInputDiv} name="mail" value={formData.mail} onChange={handleInputChange} placeholder='Correo electrónico*' maxLength={50}></input>
                        </div>
                    </div>
                    <div className={mainContentLeftFormDiv}>
                        <div className={mainContentLeftFormFullDiv}>
                            <div className={mainContentLeftFormLabelLineDiv}>
                                <div className={mainContentLeftFormLabelDiv}>
                                    Contraseña
                                </div>
                                { formError.password ?
                                    <div className={mainContentLeftFormErrorDiv}>
                                        {formError.password}
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <input className={mainContentLeftFormInputDiv} name="password" type="password" value={formData.password} onChange={handleInputChange} placeholder='Contraseña*' maxLength={50}></input>
                        </div>
                    </div>
                    <div className={loginDiv}>
                        <button className={showLoading ? loginButtonDisabled : loginButton} type="submit" onClick={login}>Iniciar sesión</button>
                    </div>
                    <div className={noAccountDiv}>
                        <Link className={noAccountLink} to="/crear-cuenta/">No tengo cuenta</Link>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    )
}

export default LoginPage
