// extracted by mini-css-extract-plugin
export var mainStyles = "login-module--mainStyles--C61dr";
export var mainContentDiv = "login-module--mainContentDiv--2Q7i5";
export var mainContentCenterDiv = "login-module--mainContentCenterDiv--kLQBR";
export var mainContentLeftTitleDiv = "login-module--mainContentLeftTitleDiv--X-7fu";
export var mainContentLeftTLogoDiv = "login-module--mainContentLeftTLogoDiv--3hIMW";
export var logoImg = "login-module--logoImg--1vOmg";
export var mainContentLeftRowDiv = "login-module--mainContentLeftRowDiv--71H9F";
export var mainContentLeftFormDiv = "login-module--mainContentLeftFormDiv--2JAqT";
export var mainContentLeftFormLabelDiv = "login-module--mainContentLeftFormLabelDiv--3FSqx";
export var mainContentLeftFormInputDiv = "login-module--mainContentLeftFormInputDiv--5PSFR";
export var mainContentLeftFormFullDiv = "login-module--mainContentLeftFormFullDiv--nHqOQ";
export var loginDiv = "login-module--loginDiv--1ZGQI";
export var loginButton = "login-module--loginButton--1s8Sd";
export var loginButtonDisabled = "login-module--loginButtonDisabled--3Xx7w";
export var alreadyHaveAccountDiv = "login-module--alreadyHaveAccountDiv--2tHlg";
export var mainContentLeftFormLabelLineDiv = "login-module--mainContentLeftFormLabelLineDiv--Ha9r0";
export var mainContentLeftFormErrorDiv = "login-module--mainContentLeftFormErrorDiv--BFa9h";
export var successfulAccountMsgDiv = "login-module--successfulAccountMsgDiv--pGD59";
export var errorMsgDiv = "login-module--errorMsgDiv--1SjiR";
export var noAccountDiv = "login-module--noAccountDiv--1OMjy";
export var noAccountLink = "login-module--noAccountLink--19nKh";