import { mainDiv, listItemsWrapper, whiteLogo, socialNetworkLink, rightsReservedDiv, 
	optionSvgLi, logoDiv, linkDiv, linkAnchor } from './footer.module.css'
import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"

class YearDiv extends React.Component {
    render() {
        return <>{(new Date().getFullYear())}</>;
    }
}

const Footer = () => {
    return (
        <div className={mainDiv}>
            <div className={logoDiv}>
                <StaticImage className={whiteLogo} width={140} src="../images/whiteLogo.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
            </div>
            <ul className={listItemsWrapper}>
				<li>
					<div className={socialNetworkLink}>
						<a className={optionSvgLi} href="https://www.facebook.com/fede.cocina.ya" target="_blank" rel="noreferrer" aria-label="Facebook">
							<div>
                                <span>
									<svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="16" height="16" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"></path></svg>
								</span>
							</div>
						</a>
					</div>
				</li>
				<li>
					<div className={socialNetworkLink}>
						<a className={optionSvgLi} href="https://www.youtube.com/FedeCocina" target="_blank" rel="noreferrer" aria-label="YouTube">
							<div>
								<span>
        							<svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="16" height="16" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
								</span>
							</div>
						</a>
					</div>
				</li>
				<li>
    				<div className={socialNetworkLink}>
						<a className={optionSvgLi} href="https://www.instagram.com/fede.cocina.ya" target="_blank" rel="noreferrer" aria-label="Instagram">
    						<div>
					    		<span>
	    		    				<svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="16" height="16" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
			    				</span>
		    				</div>
    					</a>
					</div>
				</li>
			</ul>
			<div className={linkDiv}>
				<Link to={'/paginas/contacto/'} className={linkAnchor} target="_blank">
					Contacto
				</Link>
			</div>
			<div className={linkDiv}>
				<Link to={'/paginas/politica-de-privacidad/'} className={linkAnchor} target="_blank">
					Política de Privacidad
				</Link>
			</div>
			<div className={linkDiv}>
				<Link to={'/paginas/politica-de-cookies/'} className={linkAnchor} target="_blank">
					Política de Cookies
				</Link>
			</div>
            <div className={rightsReservedDiv}>
                © <YearDiv/> Fede Cocina. Todos los derechos reservados.
            </div>
        </div>
    )
}

export default Footer